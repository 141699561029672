<template>
    <div class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="8" sm="8">
                    <div class="login-header">
                        <img class="login-logo" src="../../../src/assets/img/witcloud-logo.png" alt="Witcloud Logo " />
                        <p class="messageWelcome">Register your new account!</p>
                    </div>
                    <b-card-body class="login-box">
                        <p class="login-headline">Fill in the form below with your details:</p>
                        <wit-tabbed-form
                            :formId="formId"
                            :steps="steps"
                            no-header
                            @continue="continueForm"
                            @reset="resetForm"
                            ref="tabbedForm"
                            @finish="register"
                        >
                            <template v-slot:step-1-form="{step}">
                                <b-form-group label="Email" :label-cols="3" :horizontal="true">
                                    <b-row>
                                        <b-col md="9">
                                            <b-form-input
                                                v-model="$v.registerForm.email.$model"
                                                disabled
                                                type="email"
                                                placeholder="Enter your email address"
                                                :id="`${formId}-email`"
                                            ></b-form-input>
                                            <Feedback
                                                :state="validateRef('registerForm.email', step)"
                                                invalid="This field is required"
                                                valid="Email is valid"
                                            ></Feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>

                                <b-form-group label="Password" :label-cols="3" :horizontal="true">
                                    <b-row>
                                        <b-col md="9">
                                            <b-form-input
                                                v-model="$v.registerForm.password.$model"
                                                type="password"
                                                placeholder="Enter your password"
                                                :id="`${formId}-password`"
                                            ></b-form-input>
                                            <Feedback
                                                :state="validateRef('registerForm.password', step)"
                                                invalid="This field is required"
                                                valid="Password is valid"
                                            ></Feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>

                                <b-form-group label="Repeat password" :label-cols="3" :horizontal="true">
                                    <b-row>
                                        <b-col md="9">
                                            <b-form-input
                                                v-model="$v.registerForm.repeatedPassword.$model"
                                                type="password"
                                                placeholder="Repeat your password"
                                                :id="`${formId}-repeat-password`"
                                            ></b-form-input>
                                            <Feedback
                                                :state="validateRef('registerForm.repeatedPassword', step)"
                                                invalid="Passwords do not match"
                                            ></Feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>

                                <b-form-checkbox style="margin-left: 5px" v-model="$v.termsChecked.$model">
                                    <a target="_blank" href="https://witbee.com/witcloud/terms"
                                        >Accept terms and conditions</a
                                    >
                                </b-form-checkbox>

                                <Feedback
                                    :state="validateRef('termsChecked', step)"
                                    invalid="This field is required"
                                ></Feedback>

                                <b-form-group>
                                    <vue-recaptcha
                                        :sitekey="recaptchaSiteKey"
                                        :loadRecaptchaScript="true"
                                        @verify="onRecaptchaVerify"
                                        @expired="onRecaptchaExpired"
                                        @error="onRecaptchaError"
                                    ></vue-recaptcha>
                                </b-form-group>

                                <Feedback :state="registerError.state" :invalid="registerError.message"></Feedback>
                            </template>
                        </wit-tabbed-form>
                    </b-card-body>
                    <b-card no-body class="text-white bg-primary py-5 d-md-down-none messageRegister">
                        <b-card-body class="text-center">
                            <div class="messageRegisterBox">
                                <p class="messegeRegisterText">Already have an account?</p>
                                <b-button
                                    @click="$router.push('/auth/login')"
                                    variant="warning"
                                    class="mt-3 messegeRegisterButton"
                                    >Login here!</b-button
                                >
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email, sameAs} from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha'

import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    data() {
        return {
            registerError: {
                state: true,
                message: '',
            },
            formId: 'invite-form',
            registerForm: {
                email: '',
                password: '',
                repeatedPassword: '',
                recaptchaResponse: '',
            },
            termsChecked: null,
            cannotContinue: true,
            recaptchaExpired: false,
            recaptchaError: false,
        }
    },
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
        VueRecaptcha,
    },
    mixins: [formMixin, validationMixin],
    validations: {
        registerForm: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
            repeatedPassword: {
                required,
                sameAsPassword: sameAs(function() {
                    return this.registerForm.password
                }),
            },
            recaptcha: {
                valid: function() {
                    return (
                        Boolean(this.registerForm.recaptchaResponse) && !this.recaptchaExpired && !this.recaptchaError
                    )
                },
            },
        },
        termsChecked: {
            required,
            sameAs: sameAs(() => true),
        },
    },
    computed: {
        steps() {
            return [
                {
                    name: 'Account Settings',
                    invalid: this.$v.registerForm.$invalid,
                },
            ]
        },
        recaptchaSiteKey() {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY
        },
    },
    created() {
        this.fetchInvitation()
    },
    methods: {
        async fetchInvitation() {
            try {
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/invitations/${this.$route.params.id}`
                )

                this.registerForm.email = response.data.data.emailInvited
            } catch (exception) {
                this.$errorHandler.report(exception, 'Could not fetch invitation')
                this.$router.push('/auth/register')
            }
        },

        closeForbiddenModal() {
            this.showForbiddenModal = false
        },

        async register(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                try {
                    this.registerError.state = true
                    this.registerError.message = null

                    const data = {
                        email: this.registerForm.email,
                        password: this.registerForm.password,
                        invitationId: this.$route.params.id,
                        recaptchaResponse: this.registerForm.recaptchaResponse,
                    }

                    this.$store.commit('loading/PROCESSING', `Registering...`)

                    const response = await this.axios.post(
                        `${process.env.VUE_APP_NODE_API_HOST}/auth/registerViaInvite`,
                        data
                    )

                    localStorage.removeItem('activeProjectId')
                    this.$store.commit('loading/PROCESSED')

                    this.$router.push('/auth/login?registered=true')
                } catch (e) {
                    this.$errorHandler.report(e, 'Could not register with invitation')
                    this.$store.commit('loading/PROCESSED')

                    this.registerError.state = false
                    this.registerError.message =
                        e.response.data && e.response.data.data ? e.response.data.data.message : 'Could not register'
                }
            }
        },

        onRecaptchaVerify(key) {
            this.recaptchaError = false
            this.recaptchaExpired = false
            this.registerForm.recaptchaResponse = key
        },

        onRecaptchaExpired() {
            this.recaptchaError = false
            this.recaptchaExpired = true
            this.registerForm.recaptchaResponse = ''
        },

        onRecaptchaError() {
            this.recaptchaExpired = false
            this.recaptchaError = true
            this.registerForm.recaptchaResponse = ''
        },
    },
}
</script>

<style lang="scss">
#invite-form {
    .step-form {
        background: none;
        box-shadow: none;
    }
    #invite-form-address-line1 {
        margin-bottom: 7px;
    }
}
</style>
